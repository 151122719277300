import React, { useState, useEffect } from "react";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import "./NotificationList.css";
import Axios from "axios";
import IconButton from "../../components/inputComponents/IconButton";
import MaterialTables from "../../components/materialTable/MaterialTables";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url, {url_upload} from "../../constants/url";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import Typography from "@material-ui/core/Typography";

const authCheck  = require("../../helpers/auth.helpers");

const NotificationList = () => {
  const history = useHistory();
  const location = useLocation();
  // const { designation, areaAndZone } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage.getItem('designation');
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))
  

  const [tableContents, setTableContents] = useState([]);
  const [del, setDel] = useState(false);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);

  const [page, setPage] = useState(location.state?.currentPage || 1);
  
  const headings = [
    "SL No",
    // "Project Type",
    "Notification/Contract No",
    "Description",
    "Area",
    "Installation",
    "Notification Entry Date",
    "Date of Creation",
    "Uploaded Details",
    "Action",
  ];
  
  let areaList = [];
  areaAndZone.map((area) => {
    areaList.push(area.area._id);
  });

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.post(url + "/notifications/getNotificationByArea", { area: areaList})
      .then((response) => {
        // console.log(response.data)
        // setPaginationCount(response.data.count);
        setTotalCount(response.data.length);

        let apiData = [];
        let contents = [];
        let installTemp = {};
        apiData = response.data.reverse();
        // let i = response.data.length;
        apiData.map((data) => {
          let tempObj = {};
          // tempObj["SL No"] = i;
          // i = i - 1;
          let actions = data["isJob"] ? (
            "JOB ASSIGNED"
          ) : (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              {designation === "Technician" ? null : (
                <div style={{ marginBottom: "10px" }}>
                  <IconButton
                    iconClass="fas fa-tasks"
                    className="success"
                    hint="Job Assignment"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/jobAssignment/${data["_id"]}`)
                    }}
                  ></IconButton>
                </div>
              )}
              <div style={{ marginBottom: "10px" }}>
                <IconButton
                  iconClass="far fa-edit"
                  className="success"
                  hint="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/editNotification/${data["_id"]}`)
                  }}
                ></IconButton>
              </div>
              {designation === "Technician" ? null : (
                <div style={{ marginBottom: "10px" }}>
                  <IconButton
                    iconClass="fas fa-trash-alt"
                    className="danger"
                    hint="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      modalVisible(data["_id"])
                    }}
                  ></IconButton>
                </div>
              )}
            </div>
          );
          tempObj["SL No"] = data["projectId"];
          tempObj["_id"] = data["_id"]
          tempObj["Project Type"] = data["projectType"];
          tempObj["Notification/Contract No"] = data["notificationNumber"];
          if (data["description"] !== undefined && data["description"] !== null) {
            tempObj["Description"] = data["description"].toString().length > 100
              ? data["description"].toString().slice(0, 100)
              : data["description"];
          } else {
            // Handle the case where data["description"] is undefined or null
            tempObj["Description"] = ''; // Set a default value or handle accordingly
          }
          tempObj["Area"] = data["area"]["areaName"];
          tempObj["Installation"] = data["installation"]["name"];
          tempObj["Notification Entry Date"] = formattedDate(data["date"]);
          tempObj["Date of Creation"] = formattedDate(data["dateCreation"]);
          tempObj["Uploaded Details"] =
            data["uploadNotification"] === "no-details" ? (
              data["uploadNotification"]
            ) : (
              <a href={`${url_upload}${data["uploadNotification"]}`} target="_blank">
                View Details
              </a>
            );
          tempObj["Action"] = actions;

          contents = [...contents, tempObj];
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
}, [del]);

  const modalVisible = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteHandler(id));
    setQues("Do you want to delete this notification?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const deleteHandler = (id) => {
    setLoading(true);
    Axios.delete(url + "/notifications/" + id)
      .then((res) => {
        setLoading(false);
        modalClose();
        setDel(!del);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <div className="notificationList">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Notifications List"></ContainerHeader>
      <Paper>
        <div class="notificationListTable">
          <MaterialTables
            headings={headings}
            data={tableContents}
            title="Notification Database"
            rowClick = {(e, selectedRow) => {
              if(e.target.cellIndex === 2) {
                history.push(`/viewNotification/${selectedRow["_id"]}`)
              }
            }}
          />
        </div>
        {/* <div style={{
            display: 'flex',
            alignItems: "start",
            marginTop: "10px"
        }}>
          <Typography variant="p">Total Notifications: {totalCount}</Typography>
        </div>
        <div style={{alignItems:'center', display:'flex', justifyContent:'center', marginTop:'10px'}}>
          <Pagination count={paginationCount} page={page} onChange={handlePageChange} sx={{width:'100%'}} />
        </div> */}
      </Paper>
    </div>
  );
};

export default NotificationList;
